import React, { createContext, useState, useContext, ReactNode, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CustomSnackBar from "../components/Snackbar/CustomSnackBar";
import { getUserDetails, authenticate } from "../services/authService";
import { BaseUser, AuthContextType, AuthProviderProps } from '../types/auth'

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [token, setToken] = useState<string | null>(JSON.parse(localStorage.getItem("lexcode_was_remember_me") || "false") === true ? localStorage.getItem('lexcode_was_access_token') : sessionStorage.getItem('lexcode_was_access_token'));
  const [user, setUser] = useState<string | null>(JSON.parse(localStorage.getItem("lexcode_was_remember_me") || "false") === true ? localStorage.getItem('lexcode_was_username') : sessionStorage.getItem('lexcode_was_username')
  );
  const [userData, setUserData] = useState<BaseUser | null>(() => {
    const storedData = JSON.parse(localStorage.getItem("lexcode_was_remember_me") || "false") === true ? localStorage.getItem('lexcode_was_user_data') : sessionStorage.getItem('lexcode_was_user_data');

    return storedData ? JSON.parse(storedData) : null;
  });
  const navigate = useNavigate();
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"error" | "info" | "success" | "warning">("info");

  useEffect(() => {
    if (token) {
      autoLogout();
    }
  });

  const fetchUserData = async (access_token: string | null, username: string | null) => {
    try {
      if (access_token && username) {
        const userData = await getUserDetails(username);

        if (JSON.parse(localStorage.getItem("lexcode_was_remember_me") || "false") === true) {
          localStorage.setItem('lexcode_was_user_data', JSON.stringify(userData));
        } else {
          sessionStorage.setItem('lexcode_was_user_data', JSON.stringify(userData));
        }

        setUserData(userData);
      } else {
        console.error("Access token or username is null");
      }
    } catch (error) {
      console.error("Failed to fetch user data", error);
    }
  };

  const login = async (username: string, password: string, rememberMe: boolean) => {
    try {
      const tokenData = await authenticate(username, password);
      const expirationTime = 8 * 60 * 60 * 1000; // 8 hrs
      const expiry = Date.now() + expirationTime;

      if (!rememberMe) {
        sessionStorage.setItem("lexcode_was_access_token", tokenData.access_token);
        sessionStorage.setItem("lexcode_was_username", username);
        localStorage.setItem("lexcode_was_auth_timestamp", JSON.stringify(expiry));
      } else {
        localStorage.setItem("lexcode_was_access_token", tokenData.access_token);
        localStorage.setItem("lexcode_was_username", username);
        localStorage.setItem("lexcode_was_remember_me", JSON.stringify(rememberMe));
        localStorage.setItem("lexcode_was_auth_timestamp", JSON.stringify(expiry));
      }

      setToken(tokenData.access_token);
      setUser(username);
      setSnackbarMessage("You have logged in successfully!");
      setSnackbarSeverity("success");
      setShowSnackbar(true);
      fetchUserData(tokenData.access_token, username);
      navigate("/dashboard");
    } catch (error: any) {
      console.error("Login failed", error);
      throw new Error(error.response?.data?.detail ? error.response.data.detail : 'Invalid credentials');
    }
  };

  const logout = () => {
    const keys = [
      "lexcode_was_access_token",
      "lexcode_was_username",
      "lexcode_was_user_data",
    ];

    keys.forEach((key) => {
      sessionStorage.removeItem(key);
      localStorage.removeItem(key);
    });

    localStorage.removeItem('lexcode_was_remember_me');
    localStorage.removeItem('lexcode_was_auth_timestamp');

    setToken(null);
    setUser(null);
    setUserData(null);
    navigate("/");
  };

  const autoLogout = () => {
    const expiryTimestamp = Number(localStorage.getItem("lexcode_was_auth_timestamp"));

    if (!expiryTimestamp || Date.now() > expiryTimestamp) {
      const timeLeft = expiryTimestamp - Date.now();
      const timeout = setTimeout(() => {
        logout();
        setSnackbarMessage("Session expired. You have been logged out!");
        setSnackbarSeverity("error");
        setShowSnackbar(true);
      }, timeLeft);

      return () => clearTimeout(timeout);
    }
  }

  return (
    <AuthContext.Provider value={{ token, user, userData, login, fetchUserData, logout }}>
      <CustomSnackBar
        open={showSnackbar}
        message={snackbarMessage}
        severetype={snackbarSeverity}
        onClose={() => setShowSnackbar(false)}
      />
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
