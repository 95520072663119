import { Box, Snackbar, IconButton, Alert } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Fragment } from 'react';

interface SnackBarProps {
  open: boolean;
  message?: string;
  severetype: 'error' | 'info' | 'success' | 'warning';
  onClose: () => void;
}

const CustomSnackBar: React.FC<SnackBarProps> = ({ open, message = '', severetype, onClose }) => {
  return (
    <Box sx={{ width: 500 }}>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        autoHideDuration={5000}
        onClose={onClose}
        action={
          <Fragment>
            <IconButton
              aria-label="close"
              color="inherit"
              sx={{ p: 0.5 }}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </Fragment>
        }
      >
        <Alert onClose={onClose} severity={severetype} sx={{ width: '100%' }}>
          {message || ''}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CustomSnackBar;
