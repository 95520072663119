import React, { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Box, Toolbar } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ResponsiveAppBar from './components/ResponsiveAppBar/ResponsiveAppBar';
import { AuthProvider } from "./context/AuthContext";
import ProtectedRoutes from './components/ProtectedRoutes/ProtectedRoutes';
import './App.css';

// Lazy-loaded components
const Homepage = lazy(() => import('./pages/HomePage/HomePage'));
const Login = lazy(() => import('./pages/Auth/Login'));
const ResetPassword = lazy(() => import('./pages/Auth/ResetPassword'));
const ManageUser = lazy(() => import('./pages/Auth/ManageUser'));
const NotFound = lazy(() => import('./pages/Auth/NotFound'));

// // IPM Automation
const GlossaryExtraction = lazy(() => import('./pages/IPM/GlossaryExtraction/GlossaryExtraction'));
const DeadlineCalculator = lazy(() => import('./pages/IPM/DeadlineCalculator/DeadlineCalculator'));
const LanguageSeparator = lazy(() => import('./pages/IPM/LanguageSeparator/LanguageSeparator'));
const PptCharCount = lazy(() => import('./pages/IPM/PptCharCount/PptCharCount'))
const WhiteCellSeparator = lazy(() => import('./pages/IPM/WhiteCellSeparator/WhiteCellSeparator'));
const IpmAllocation = lazy(() => import('./pages/IPM/IpmAllocation/IpmAllocation'));
const IpmPmTool = lazy(() => import('./pages/IPM/IpmPmTool/IpmPmTool'));

// // ATPE Automation
const WorkloadAllocation = lazy(() => import('./pages/ATPE/Allocation/Allocation'));
const ATPEPMTool = lazy(() => import('./pages/ATPE/PMTool/ATPEPMTool'));
const EditorSchedule = lazy(() => import('./pages/ATPE/EditorSchedule/EditorSchedule'));
const Dashboard = lazy(() => import('./pages/ATPE/Dashboard/Dashboard'));
const WordCount = lazy(() => import('./pages/ATPE/WordCount/WordCount'));
const EditorTasks = lazy(() => import('./pages/ATPE/EditorTasks/EditorTasks'));

// // TAMSE Automation
const FileClassifier = lazy(() => import('./pages/TAMSE/FileClassifier/FileClassifier'));

//LSU Automation
const CertificationTracker = lazy(() => import('./pages/LSU/CertificationTracker/CertificationTracker'));

// MUI Theme configuration
const theme = createTheme({
  palette: {
    primary: { main: '#262364' },
    secondary: { main: '#A82B69' },
  },
  components: {
    MuiButton: { styleOverrides: { root: { marginTop: 10, padding: 10 } } },
    MuiPaper: { styleOverrides: { root: { padding: 15, marginBottom: 10 } } },
  },
});

const App: React.FC = () => {
  return (
    <div className="App">
      <AuthProvider>
        <ResponsiveAppBar />
        <Box>
          <Toolbar />
          <ThemeProvider theme={theme}>
            <Suspense fallback={<>...</>}>
              <Routes>
                <Route path="/" element={<Suspense fallback={<>...</>}> <Login /> </Suspense>} />

                {/* ADMIN */}
                <Route element={<ProtectedRoutes role="admin" department='' />}>
                  <Route path="/manage-users" element={<Suspense fallback={<>...</>}> <ManageUser /> </Suspense>} />
                </Route>

                {/* ALL */}
                <Route element={<ProtectedRoutes role="" department="" />}>
                  <Route path="/reset-password" element={<Suspense fallback={<>...</>}> <ResetPassword /> </Suspense>} />
                  {/* HOME */}
                  <Route path="/dashboard" element={<Suspense fallback={<>...</>}> <Homepage /> </Suspense>} />
                </Route>

                {/* IPM Automation */}
                <Route element={<ProtectedRoutes role="user" department='ipm' />}>
                  <Route path="/GlossaryExtraction" element={<Suspense fallback={<>...</>}> <GlossaryExtraction /> </Suspense>} />
                  <Route path="/DeadlineCalculator" element={<Suspense fallback={<>...</>}> <DeadlineCalculator /> </Suspense>} />
                  <Route path="/LanguageSeparator" element={<Suspense fallback={<>...</>}> <LanguageSeparator /> </Suspense>} />
                  <Route path="/PptCharCount" element={<Suspense fallback={<>...</>}> <PptCharCount /> </Suspense>} />
                  <Route path="/WhiteCellSeparator" element={<Suspense fallback={<>...</>}> <WhiteCellSeparator /> </Suspense>} />
                  <Route path="/IpmAllocation" element={<Suspense fallback={<>...</>}> <IpmAllocation /> </Suspense>} />
                  <Route path="/IpmPmTool" element={<Suspense fallback={<>...</>}> <IpmPmTool /> </Suspense>} />
                </Route>
                {/* ATPE Automation */}
                <Route element={<ProtectedRoutes role="user" department='atpe' />}>
                  <Route path="/WorkloadAllocation" element={<Suspense fallback={<>...</>}> <WorkloadAllocation /> </Suspense>} />
                  <Route path='/PMTool' element={<Suspense fallback={<>...</>}> <ATPEPMTool /></Suspense>} />
                  <Route path="/EditorSchedule" element={<Suspense fallback={<>...</>}><EditorSchedule /></Suspense>} />
                  <Route path='/ATPEDashboard' element={<Suspense fallback={<>...</>}> <Dashboard/></Suspense>} />
                  <Route path='/WordCount' element={<Suspense fallback={<>...</>}> <WordCount /></Suspense>} />
                  <Route path="/EditorTasks" element={<Suspense fallback={<>...</>}> <EditorTasks /> </Suspense>} />
                </Route>

                <Route element={<ProtectedRoutes role="user" department='' />}>
                  <Route path="/FileClassifier" element={<Suspense fallback={<>...</>}> <FileClassifier /> </Suspense>} />
                </Route>

                <Route element={<ProtectedRoutes role="user" department='lsu' />}>
                  <Route path="/CertificationTracker" element={<Suspense fallback={<>...</>}> <CertificationTracker /> </Suspense>} />
                </Route>

                {/* Catch-all route for 404 */}
                <Route path="*" element={<Suspense fallback={<>...</>}> <NotFound /> </Suspense>} />

              </Routes>
            </Suspense>
          </ThemeProvider>
        </Box>
      </AuthProvider>
    </div>
  );
};

export default App;
