import axios from 'axios';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

console.log(api);

api.interceptors.request.use(
  (config) => {
    //TODO: Add config here as needed, we don't use any auth for any of the WAS endpoints right now
    const token = JSON.parse(localStorage.getItem("lexcode_was_remember_me") || "false") === true ? localStorage.getItem('lexcode_was_access_token') : sessionStorage.getItem('lexcode_was_access_token');

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

export default api;
