import api from "../utils/axios"
import { User, editUserPayloadData } from "../types/auth";

export const getUserDetails = async (username: string) => {
  try {
    const response = await api.get(`/Authentication/user/${username}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching user details:", error);
    throw error;
  }
};

export const authenticate = async (username: string, password: string) => {
  try {
    const response = await api.post(
      "/Authentication/token",
      new URLSearchParams({
        username: username,
        password: password,
      }),
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error authenticating user:", error);
    throw error;
  }
};

export const resetPassword = async (username: string | null, newPassword: string, confirmPassword: string) => {
  try {
    const response = await api.post("/Authentication/reset-password",
      {
        username: username,
        new_password: newPassword,
        new_password_confirm: confirmPassword,
      }
    );

    return response.data;
  } catch(error) {
    throw error;
  }
}

export const fetchAllUsers = async () => {
  try {
    const response = await api.get('/Authentication/users');
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const updateUser = async (editUserPayload: editUserPayloadData) => {
  try {
    const response = await api.post("/Authentication/update", null,
      {
        params: editUserPayload
      });
    return response.data;
  } catch (error) {
    throw error
  }
}

export const addUser = async (newUser: User) => {
  try {
    const response = await api.post("/Authentication/register", null,
      {
        params: newUser,
      });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const deleteUser = async (username: string) => {
  try {
    const response = await api.delete(`/Authentication/delete/${username}`);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const updateUserStatus = async (event: React.ChangeEvent<HTMLInputElement>, username: string) => {
  try {
    const response = await api.post(`/Authentication/update_status`, null,
      {
        params: {
          username: username,
          disabled: event.target.checked ? false : true,
        }
      });
    return response.data;
  } catch (error) {
    throw error;
  }
}