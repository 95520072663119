import { Outlet, Navigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

interface ProtectedRoutesProps {
  role: string;
  department: string;
}


const ProtectedRoutes: React.FC<ProtectedRoutesProps> = ({role, department}) => {
  const { token, userData } = useAuth();
  // return token ? <Outlet /> : <Navigate to="/" />

  if(!token) {
    return <Navigate to="/" />
  }

  if(userData?.role === "user" && role === "admin") {
    return <Navigate to="/dashboard" />
  }

  if(!userData?.ipm && department === "ipm") {
    return <Navigate to="/dashboard" />
  }

  if (!userData?.atpe && department === "atpe") {
    return <Navigate to="/dashboard" />
  }

  if (!userData?.lsu && department === "lsu") {
    return <Navigate to="/dashboard" />
  }

  return <Outlet />
}

export default ProtectedRoutes;