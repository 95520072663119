import { useState } from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Box, Button, CssBaseline, Divider, Drawer, IconButton } from '@mui/material';
import { List, ListItem, ListItemButton, ListItemText, Menu, MenuItem, Toolbar, Typography } from '@mui/material';
import { MenuOutlined } from '@mui/icons-material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useAuth } from "../../context/AuthContext";

const drawerWidth = 240;

interface NavItem {
  link: string;
  name: string;
}

const navItems: NavItem[] = [
  { link: '/GlossaryExtraction', name: 'Glossary Extraction' },
  { link: '/DeadlineCalculator', name: 'Deadline Calculator' },
  { link: '/LanguageSeparator', name: 'Language Separator' },
  { link: '/PptCharCount', name: 'Powerpoint Character Count' },
  { link: '/WhiteCellSeparator', name: 'RTF White Cell Separator' },
  { link: '/IPMAllocation', name: 'IPM Allocation' },
  { link: '/IPMPMTool', name: 'IPM PM Tool' },
  { link: '/WorkloadAllocation', name: 'Workload Allocation' },
  { link: '/ATPEDashboard', name: 'Allocation Dashboard' },
  { link: '/EditorSchedule', name: 'Editor Schedule' },
  { link: '/EditorTasks', name: 'Editor Tasks' },
  { link: '/PMTool', name: 'File Tracker' },
  { link: '/WordCount', name: 'Word Count' },
  { link: '/CertificationTracker', name: 'Certication Tracker w/ QR Code Generation & Link' },
  { link: '/FileClassifier', name: 'File Block & Zone Classifier' }
];

const dropdown1Items: NavItem[] = [
  { link: '/GlossaryExtraction', name: 'Glossary Extraction' },
  { link: '/DeadlineCalculator', name: 'Deadline Calculator' },
  { link: '/LanguageSeparator', name: 'Language Separator' },
  { link: '/PptCharCount', name: 'Powerpoint Character Count' },
  { link: '/WhiteCellSeparator', name: 'RTF White Cell Separator' },
  { link: '/IPMAllocation', name: 'IPM Allocation' },
  { link: '/IPMPMTool', name: 'IPM PM Tool' },
];

const dropdown2Items: NavItem[] = [
  { link: '/WorkloadAllocation', name: 'Workload Allocation' },
  { link: '/ATPEDashboard', name: 'Allocation Dashboard' },
  { link: '/EditorSchedule', name: 'Editor Schedule' },
  { link: '/EditorTasks', name: 'Editor Tasks' },
  { link: '/PMTool', name: 'File Tracker' },
  { link: '/WordCount', name: 'Word Count' },
];

const dropdown5Items: NavItem[] = [
  {
    link: '/FileClassifier', name: 'File Block & Zone Classifier'
  }
];

const dropdown6Items: NavItem[] = [
  {
    link: '/CertificationTracker', name: 'Certication Tracker w/ QR Code Generation & Link'
  }
];

interface ResponsiveAppBarProps {
  window?: () => Window;
}

const ResponsiveAppBar: React.FC<ResponsiveAppBarProps> = (props) => {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl1, setAnchorEl1] = useState<null | HTMLElement>(null);
  const [anchorEl2, setAnchorEl2] = useState<null | HTMLElement>(null);
  const [anchorEl3, setAnchorEl3] = useState<null | HTMLElement>(null);
  const [anchorEl4, setAnchorEl4] = useState<null | HTMLElement>(null);
  const [anchorEl5, setAnchorEl5] = useState<null | HTMLElement>(null);
  const [anchorEl6, setAnchorEl6] = useState<null | HTMLElement>(null);
  const { user, token, userData, logout } = useAuth();

  const handleLogout = (): void => {
    logout();
    setAnchorEl3(null);
  };

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleDropdown1Open = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleDropdown2Open = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleDropdown3Open = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl3(event.currentTarget);
  };

  const handleDropdown4Open = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl4(event.currentTarget);
  };

  const handleDropdown5Open = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl5(event.currentTarget);
  };

  const handleDropdown6Open = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl6(event.currentTarget);
  };

  const handleDropdownClose = () => {
    setAnchorEl1(null);
    setAnchorEl2(null);
    setAnchorEl3(null);
    setAnchorEl4(null);
    setAnchorEl5(null);
    setAnchorEl6(null);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        Lexcode
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <Link key={item.name} to={item.link}>
            <ListItem disablePadding>
              <ListItemButton sx={{ textAlign: 'center' }}>
                <ListItemText primary={item.name} />
              </ListItemButton>
            </ListItem>
          </Link>
        ))}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box>
      <CssBaseline />
      <AppBar component="nav" style={{
        background: 'linear-gradient(90deg, #262364, #A82B69)',
      }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: 'none' } }}
          >
            <MenuOutlined />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'none', md: 'block' } }}
          >
            <Link
              to={token ? '/dashboard' : '/'}
              style={{ textDecoration: 'none', color: '#fff' }}
            >
              Lexcode Work Automation System
            </Link>
          </Typography>
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            {token && (<>
              {userData?.ipm ? (<>
                <Button sx={{ color: '#fff' }} aria-controls="dropdown1-menu" aria-haspopup="true" onClick={handleDropdown1Open}>
                  IPM
                </Button>
                <Menu
                  id="dropdown1-menu"
                  anchorEl={anchorEl1}
                  open={Boolean(anchorEl1)}
                  onClose={handleDropdownClose}
                >
                  {dropdown1Items.map((item) => (
                    <MenuItem key={item.name} onClick={handleDropdownClose}>
                      <Link to={item.link} style={{ textDecoration: 'none', color: 'inherit' }}>
                        {item.name}
                      </Link>
                    </MenuItem>
                  ))}
                </Menu>
              </>) : null}
              {userData?.atpe ? (<>
                <Button sx={{ color: '#fff' }} aria-controls="dropdown2-menu" aria-haspopup="true" onClick={handleDropdown2Open}>
                  ATPE
                </Button>
                <Menu
                  id="dropdown2-menu"
                  anchorEl={anchorEl2}
                  open={Boolean(anchorEl2)}
                  onClose={handleDropdownClose}
                >
                  {dropdown2Items.map((item) => (
                    <MenuItem key={item.name} onClick={handleDropdownClose}>
                      <Link to={item.link} style={{ textDecoration: 'none', color: 'inherit' }}>
                        {item.name}
                      </Link>
                    </MenuItem>
                  ))}
                </Menu>
              </>) : null}
              {userData?.lsu ? (<>
                <Button sx={{ color: '#fff' }} aria-controls="dropdown6-menu" aria-haspopup="true" onClick={handleDropdown6Open}>
                  LSU
                </Button>
                <Menu
                  id="dropdown6-menu"
                  anchorEl={anchorEl6}
                  open={Boolean(anchorEl6)}
                  onClose={handleDropdownClose}
                >
                  {dropdown6Items.map((item) => (
                    <MenuItem key={item.name} onClick={handleDropdownClose}>
                      <Link to={item.link} style={{ textDecoration: 'none', color: 'inherit' }}>
                        {item.name}
                      </Link>
                    </MenuItem>
                  ))}
                </Menu>
              </>) : null}
              <Button sx={{ color: '#fff' }} aria-controls="dropdown5-menu" aria-haspopup="true" onClick={handleDropdown5Open}>
                TAMSE
              </Button>
              <Menu
                id="dropdown5-menu"
                anchorEl={anchorEl5}
                open={Boolean(anchorEl5)}
                onClose={handleDropdownClose}
              >
                {dropdown5Items.map((item) => (
                  <MenuItem key={item.name} onClick={handleDropdownClose}>
                    <Link to={item.link} style={{ textDecoration: 'none', color: 'inherit' }}>
                      {item.name}
                    </Link>
                  </MenuItem>
                ))}
              </Menu>
              {userData?.role === 'admin' && (
                <>
                  <Button sx={{ color: '#fff' }} aria-controls="dropdown4-menu" aria-haspopup="true" onClick={handleDropdown4Open}>
                    ADMIN
                  </Button>
                  <Menu
                    id="dropdown4-menu"
                    anchorEl={anchorEl4}
                    open={Boolean(anchorEl4)}
                    onClose={handleDropdownClose}
                  >
                    <MenuItem onClick={handleDropdownClose}>
                      <Link to="manage-users" style={{ textDecoration: 'none', color: 'inherit' }}>
                        Manage Users
                      </Link>
                    </MenuItem>
                  </Menu>
                </>
              )}
              <span>| Welcome {user}</span>
              <Button sx={{ color: '#fff' }} aria-controls="dropdown3-menu" aria-haspopup="true" onClick={handleDropdown3Open}>
                <ArrowDropDownIcon />
              </Button>
              <Menu
                id="dropdown3-menu"
                anchorEl={anchorEl3}
                open={Boolean(anchorEl3)}
                onClose={handleDropdownClose}
              >
                <MenuItem>
                  <Link to="reset-password" onClick={handleDropdownClose} style={{ textDecoration: 'none', color: 'inherit' }} >
                    Reset Password
                  </Link>
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                  <span>Logout</span>
                </MenuItem>
              </Menu></>)}

          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
};

export default ResponsiveAppBar;
